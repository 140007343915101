import React from 'react';

const NotFound = (props) => (
    <header className="top">
        <h1>
            Seite wurde nicht gefunden
        </h1>
    </header>
)

export default NotFound;