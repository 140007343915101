import React from 'react';
import { formatPrice } from '../helpers';

class Fish extends React.Component {
    handleClick = () => {
        this.props.addToOrder(this.props.index);
    }

    render() {
        /* 
        Der Einfach heit können alle Werte im Objekt details in Variablen geschrieben werden
        const image=this.props.details.image;
        const name=this.props.details.name;
        ES GEHT ABER SCHNELLER!!!
        Siehe:
        */
       const { image, name, desc, price, status } = this.props.details

       const isAvailable = status ==="available";

        return (
            <li className="menu-fish">
                <img src={image} alt={name} />
                <h3 className="fish-name">
                    {name}
                    <span className="price">{formatPrice(price)}</span>
                </h3>
                <p>{desc}</p>
                <button disabled={!isAvailable} onClick={this.handleClick}>
                    {isAvailable ? 'Add To Card' : 'Sold Out!'}
                </button>
            </li>
        )
    }
}

export default Fish;