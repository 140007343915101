import React from 'react';
import Header from './Header'
import Order from './Order'
import Inventory from './Inventory'
import sampleFishes from '../sample-fishes';
import Fish from './Fish';
import base from '../base';

class App extends React.Component {
    state = {
        fishes: {},
        order: {}
    };
    componentDidMount() {
        const params = this.props.match.params;
        // first reinstate our localStorage
        const localStorageRef = localStorage.getItem(params.storeId);
        console.log(localStorageRef);
        if(localStorageRef){
            this.setState({order: JSON.parse(localStorageRef)});
        }

        this.ref = base.syncState(`${params.storeId}/fishes`, {
            context: this,
            state: 'fishes'
        });
    }

    componentDidUpdate() {
        localStorage.setItem(this.props.match.params.storeId, JSON.stringify(this.state.order));
    }

    componentWillUnmount(){
        base.removeBinding(this.ref);
    }

    addFish = fish => {
        // 1. Take a copy of the existing state
        const fishes ={ ...this.state.fishes}
        // 2. Add our new fish to that fishes variable
        fishes[`fish${Date.now()}`] = fish; // WICHTIG: ` anstelle von '
        // 3. Set the new fishes object to state
        this.setState({
            fishes: fishes
        });
    };

    updateFish = (key, updatedFish) => {
        // 1. Take a copy of the current state
        const fishes ={ ...this.state.fishes}
        // 2. Add our new fish to that fishes variable
        fishes[key] = updatedFish; // WICHTIG: ` anstelle von '
        // 3. Set the new fishes object to state
        this.setState({
            fishes: fishes
        });
    };

    deleteFish = (key) => {
        // 1. take a copy of state
        const fishes = { ...this.state.fishes };
        // 2. Update the state
        fishes[key] = null;
        // 3. update state
        this.setState({
            fishes: fishes
        });
    }

    loadSampleFishes = () => {
        this.setState({ fishes: sampleFishes });
    };

    addToOrder = (key) => {
        // 1. Take a copy of state
        const order = { ...this.state.order };
        // 2. Either add to order, or update the number in our order
        order[key] = order[key] + 1 || 1;
        // 3. Call setState to update our state object
        this.setState({ order:order }); // also possible because its the same name this.setState({ order })
    };

    removeFromOrder = (key) => {
        // 1. Take a copy of state
        const order = { ...this.state.order };
        // 2. Either delet from order, or update the number in our order
        delete order[key];
        // 3. Call setState to update our state object
        this.setState({ order:order }); // also possible: this.setState({ order })   Reason: because its the same name 
    };

    render() {
        return (
            <div className="catch-of-the-day">
                <div className="menu">
                    <Header tagline="Fresh Seafood Market" />
                    <ul className="fishes">
                        {Object.keys(this.state.fishes).map(key =>
                        <Fish
                            key={key}
                            index={key}
                            details={this.state.fishes[key]}
                            addToOrder={this.addToOrder} 
                        />
                        )}
                    </ul>
                </div>
                { /* <Order {...this.state} />anstelle von einzhelen Variablen den gedamten state als prop übergeben */}
                <Order 
                    fishes={this.state.fishes}
                    order={this.state.order}
                    removeFromOrder={this.removeFromOrder}
                />
                <Inventory
                    addFish={this.addFish}
                    loadSampleFishes={this.loadSampleFishes}
                    fishes={this.state.fishes}
                    updateFish={this.updateFish}
                    deleteFish={this.deleteFish}
                    storeId={this.props.match.params.storeId}
                />
            </div>
        )
    }
}

export default App;