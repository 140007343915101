import React from 'react';
import {formatPrice} from '../helpers';
import { CSSTransition, TransitionGroup } from "react-transition-group"; 
class Order extends React.Component {
    renderOrder = key => {
        const fish = this.props.fishes[key];
        const count = this.props.order[key];
        const isAvailable = fish && fish.status === "available";
        // LOCALCAH PROBLEM: Make sure the fish is loading before we continue!
        //Reason: we render the Order from the local cash,
        // but the List of fishes are at that time not back from the firebase (see app.js)
        // componentDidMount() --- const localStorageRef = localStorage.getItem(params.storeId);
        if (!fish) return null;

        if (!isAvailable) {
            return (
                <CSSTransition classNames="order" key={key} timeout={{ enter: 500, exit: 500}}>
                <li key={key}>
                    Sorry {fish ? fish.name : "this fish" } is not longer available
                </li>
                </CSSTransition>
            );
        }
        return (
            <CSSTransition classNames="order" key={key} timeout={{ enter: 500, exit: 500}}>
            <li key={key}>
                <span>
                    <TransitionGroup component="span" className="count">
                        <CSSTransition
                            classNames="count" 
                            key="{count}"
                            timeout={{ enter: 500, exit: 500 }}
                        >
                            <span>{count}</span>
                        </CSSTransition>
                    </TransitionGroup>
                    lbs {fish.name}...
                    {formatPrice(count * fish.price)}...
                    <button onClick={() => this.props.removeFromOrder(key)}>&times;</button>
                </span>
            </li>
            </CSSTransition>
        );
    }
    render() {
        const orderIds = Object.keys(this.props.order);
        const total = orderIds.reduce( (prevTotal, key) => {
            const fish = this.props.fishes[key];
            const count = this.props.order[key];
            const isAvailable = fish && fish.status === 'available';
            if(isAvailable) {
                return prevTotal + (count * fish.price);
            }
            return prevTotal;
        }, 0);
        return (
           <div className="order-wrap">
               <h2>Order</h2>
               <TransitionGroup component="ul" className="order">
                    {orderIds.map(this.renderOrder)}
               </TransitionGroup>
               <div className="total">
                   Total:
                    <strong>{formatPrice(total)}</strong>
               </div>
           </div>

        )
    }
}

export default Order;