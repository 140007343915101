import Rebase from 're-base';
import firebase from "firebase";


const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyD2s2cdH1uqU2pGtawkt6H8lmIIhumP2Qc",
    authDomain: "catch-of-the-day-nk-7c590.firebaseapp.com",
    databaseURL: "https://catch-of-the-day-nk-7c590.firebaseio.com"
});

const base = Rebase.createClass(firebaseApp.database());

//This is a named export
export { firebaseApp };

//This is a default export
export default base;