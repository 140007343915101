import React from 'react';
import { getFunName } from '../helpers';

class StorePicker extends React.Component {
    myInput = React.createRef();

    goToStore = event => {
        // 1. Stop the Form for submitting
        event.preventDefault();
        // 2. get the Text from that input
        const storeName = this.myInput.current.value;
        //console.log(storeName);
        this.props.history.push(`/store/${storeName}`);
        //console.log(event);
        // console.log(this.myInput.value.value);
        // 3. Change the page to /store/what is entered in the textfield
        //this.props.push('/store/${this.')
        // console.log(event)
    };

    render() {
        return (
            <form className="store-selector" onSubmit={this.goToStore}>
                <h2>Enter a Sore Name</h2>
                <input 
                    type="text"
                    ref={this.myInput}
                    required
                    placeholder="Store Name"
                    defaultValue={getFunName()}
                    />
                <button type="submit">Visist Store -></button>
            </form>
        )
    }
}

export default StorePicker;