import React from 'react';

class EditFishForm extends React.Component {
    handleChange = (event) => {
        console.log(event.currentTarget.value + ":" +event.currentTarget.value);
        // update that fish
        // 1. Take a copy of the current fish
        const updatedFish = {
            ...this.props.fish,
            // name: event.currentTarget.value
            // dies soll für alle names gelten daher :
            [event.currentTarget.name]: event.currentTarget.value
        };
        this.props.updateFish(this.props.index, updatedFish)
    };
    render() {
        return (
           <div className="fish-edit">
            <input name="name" type="text" onChange={this.handleChange} value={this.props.fish.name} />
            <input name="price" type="text" onChange={this.handleChange} value={this.props.fish.price} />
            <select name="status" onChange={this.handleChange} value={this.props.fish.status}>
                <option value="available">Fresh</option>
                <option value="unavailable">Sold Out!</option>
            </select>
            <textarea name="desc" onChange={this.handleChange} value={this.props.fish.desc}></textarea>
            <input name="image" onChange={this.handleChange} type="text"value={this.props.fish.image}/>
            {/* Folgendes "onClick muss nicht eine Funktion aufrufen, man kann diese direkt als Function dem onClick zuordnen" */}
            <button onClick={() => this.props.deleteFish(this.props.index)}>Delete</button>
           </div>
        )
    }
}

export default EditFishForm